import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guide.scss';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { WWCharacter } from '../../../modules/ww/common/components/ww-character';

const WWGuidesResonators: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ww-guide'} game="ww">
      <ul className="breadcrumb">
        <li>
          <Link to="/wuthering-waves/">Wuthering Waves</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/wuthering-waves/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Resonators Explained</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ww/categories/category_reso.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Wuthering Waves Resonators Explained</h1>
          <h2>
            The Resonators (characters) system in Wuthering Waves explained.
          </h2>
          <p>
            Last updated: <strong>04/05/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Resonators" />
        <div className="employee-container for-nikke">
          <WWCharacter mode="icon" slug="verina" enablePopover showLabel />
          <WWCharacter mode="icon" slug="encore" enablePopover showLabel />
          <WWCharacter mode="icon" slug="danjin" enablePopover showLabel />
          <WWCharacter mode="icon" slug="baizhi" enablePopover showLabel />
        </div>
        <p>
          Resonators are the playable units in Wuthering Waves. They are mainly
          obtained via Convenes, the game’s gacha system. However, some
          Resonators can be obtained for free via in-game missions or events.
        </p>
        <SectionHeader title="Resonator Stats" />
        <ul>
          <li>
            <strong>HP</strong> - How much damage a Resonator can take before
            dying,
          </li>
          <li>
            <strong>ATK</strong> - How much damage a Resonator deals,
          </li>
          <li>
            <strong>DEF</strong> - Reduces the damage a Resonator takes,
          </li>
          <li>
            <strong>Max Stamina</strong> - Determines how many continuous
            stamina using actions (dashing, dodging, climbing, swimming and some
            attacks use stamina) you can take before being forcibly stopped,
          </li>
          <li>
            <strong>Crit Rate</strong> - How likely it is for the Resonator to
            land a critical hit,
          </li>
          <li>
            <strong>Crit DMG</strong> - Determines the damage multiplier when
            landing a critical hit,
          </li>
          <li>
            <strong>Energy Regen</strong> - Determines how fast a Resonator
            charges energy for their Resonance Liberation (Ultimate),
          </li>
          <li>
            <strong>Resonance Skill DMG Bonus</strong> - Increases the damage of
            Resonance Skill,
          </li>
          <li>
            <strong>Basic Attack DMG Bonus</strong> - Increases the damage of
            Basic Attacks,
          </li>
          <li>
            <strong>Heavy Attack DMG Bonus</strong> - Increases the damage of
            Heavy Attacks,
          </li>
          <li>
            <strong>Resonance Liberation DMG Bonus</strong> - Increases the
            damage of Resonance Liberation (Ultimate),
          </li>
          <li>
            <strong>Elemental DMG Bonus</strong> - Increases all damage a
            character deals. Note that there are six different elements in the
            game and each element has its own separate Elemental DMG Bonus stat,
          </li>
          <li>
            <strong>Elemental DMG RES</strong> - Reduces the damage a Resonator
            takes from a specific Element,
          </li>
          <li>
            <strong>Healing Bonus</strong> - Increases the amount of healing a
            Resonator provides.
          </li>
        </ul>
        <SectionHeader title="Resonator Elements & Weapons" />
        <p>
          Each Resonator in Wuthering Waves has an Element associated with them.
          A Resonator’s Element determines the damage type of their attacks.
        </p>
        <p>There are currently 6 Elements in the game:</p>
        <ul>
          <li>
            <strong className="Glacio">Glacio</strong>
          </li>
          <li>
            <strong className="Fusion">Fusion</strong>
          </li>
          <li>
            <strong className="Electro">Electro</strong>
          </li>
          <li>
            <strong className="Aero">Aero</strong>
          </li>
          <li>
            <strong className="Spectro">Spectro</strong>
          </li>
          <li>
            <strong className="Havoc">Havoc</strong>
          </li>
        </ul>
        <p>
          Each Resonator in Wuthering Waves uses a specific Weapon type. A
          Resonator’s Weapon type will give you a general idea as to how they
          fight as well as which Weapons they can equip.
        </p>
        <p>There are currently 5 Weapon types in the game:</p>
        <ul>
          <li>Broadblade</li>
          <li>Gauntlets</li>
          <li>Pistols</li>
          <li>Rectifier</li>
          <li>Sword</li>
        </ul>
        <SectionHeader title="Resonator Skills" />
        <p>
          Each Resonator in Wuthering Waves has 6 different abilities that
          define their playstyle.
        </p>
        <ul>
          <li>
            <strong>Basic Attack</strong> is a Character’s auto-attack and is
            always available. It’s generally the most used ability and it is
            usually the main way to generate the various resources other
            abilities use. You can also activate Heavy Attack by long-pressing
            the Basic Attack button.
          </li>
          <li>
            <strong>Resonance Skill</strong> is a combat ability that a
            Resonator can use with effects ranging from dealing damage to
            healing your allies. This ability usually has a cooldown and can not
            be used back to back.
          </li>
          <li>
            <strong>Resonance Liberation</strong> is generally their strongest
            combat ability. To use Resonance Liberation, it must first be
            charged through certain combat actions such as dealing damage to
            enemies or triggering Extreme Evasion. It must also be recharged
            after every use.
          </li>
          <li>
            <strong>Forte Circuit</strong> is a gameplay defining ability that
            is unique for every Resonator. It is represented by a gauge above
            the health bar. Once it is available, it will change into the color
            of that Resonator’s Element and will gain a waveform-like
            appearance. It can be activated by using the right abilities while
            it is available.
          </li>
          <li>
            <strong>Outro and Intro Skills</strong> are abilities that activate
            upon switching Resonator once the current Resonator has filled the
            Concerto Energy. It is represented by a gauge to the left of the
            health bar. Upon switching, the current Resonator will activate
            their Outro Skill, buffing the next Resonator you are switching
            into. While Intro Skills are unique attacks that the next Resonator
            will perform.
          </li>
        </ul>
        <SectionHeader title="Resonator List" />
        <p>If you want to check all available Resonators, check this guide:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Resonator List"
            link="/wuthering-waves/characters"
            image={
              <StaticImage
                src="../../../images/ww/categories/category_characters.jpg"
                alt="Resonator List"
              />
            }
          />
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default WWGuidesResonators;

export const Head: React.FC = () => (
  <Seo
    title="Resonators Explained | Wuthering Waves | Prydwen Institute"
    description="The Resonators (characters) system in Wuthering Waves explained."
    game="ww"
  />
);
